@import "../../../../../../shared/styles/common";

.textBold{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: $black;
}

.paddingLeft{
  padding-left:8px ;
}

