@import '../../../../../shared/styles/common';


%textTableHeaderDefault{
  @include fontFamily(Rubik);
  font-size: 14px;
  line-height: 22px;
  color: $black;
  font-style: normal;
}

.tableWrapper{
  margin-top: 16px;
  .content{
    padding: 0 ;
    @include media-sm-down {
      padding: 0;
    }
  }
}

.tableHeader{
 @extend %textTableHeaderDefault;
  text-align: center;
  width: 100%;
  font-weight: 400;
  padding: 0 8px 8px 0;
}

.tableHeaderLast{
  @extend %textTableHeaderDefault;
  padding: 0 0 8px 8px;
}

.textBold{
  @extend %textTableHeaderDefault;
  font-weight: 500;
}

