// media aliases and breakpoints
$screen-sm-min: 576px;
$screen-md-min: 768px;
$screen-lg-min: 1280px;
$screen-xl-min: 1920px;

$screen-xs-max: $screen-sm-min - 1px;
$screen-sm-max: $screen-md-min - 1px;
$screen-md-max: $screen-lg-min - 1px;
$screen-lg-max: $screen-xl-min -1px;

// media only queries
@mixin media-xs-only {
  @media screen and (max-width: #{$screen-xs-max}) {
    @content;
  }
}

@mixin media-sm-only {
  @media screen and (min-width: #{$screen-sm-min}) and (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin media-md-only {
  @media screen and (min-width: #{$screen-md-min}) and (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin media-lg-only {
  @media screen and (min-width: #{$screen-lg-min}) and (max-width: #{$screen-lg-max}) {
    @content;
  }
}

// media down queries
@mixin media-sm-down {
  @media screen and (max-width: #{$screen-xs-max}) {
    @content;
  }
}

@mixin media-md-down {
  @media screen and (max-width: #{$screen-sm-max}) {
    @content;
  }
}

@mixin media-lg-down {
  @media screen and (max-width: #{$screen-md-max}) {
    @content;
  }
}

@mixin media-xl-down {
  @media screen and (max-width: #{$screen-lg-max}) {
    @content;
  }
}

// media up queries
@mixin media-xs-up {
  @media screen and (min-width: #{$screen-sm-min}) {
    @content;
  }
}

@mixin media-sm-up {
  @media screen and (min-width: #{$screen-md-min}) {
    @content;
  }
}

@mixin media-md-up {
  @media screen and (min-width: #{$screen-lg-min}) {
    @content;
  }
}

@mixin media-lg-up {
  @media screen and (min-width: #{$screen-xl-min}) {
    @content;
  }
}
