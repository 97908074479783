@import "src/shared/styles/common";

.wrapper{
  all: unset;
  display: flex;
  flex-direction: column;
  border: 1px solid $grey200;
  border-radius: 8px;
  background: $white;
  width: min-content;
  cursor: pointer;
}

.imageWrapper{
  position: relative;
  width: 294px;
  height: 180px;
  border-radius: 8px;
  @include media-sm-down{
    width: 312px;
    height: 192px;
  }
}
.productImage{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-right-radius:8px;
  border-top-left-radius:8px;
}

.logo{
  width: 42px;
  height: 42px;
  border-radius: 50%;
  position: absolute;
  bottom: 6px;
  right: 6px;
}

.infoWrapper{
  padding: 6px 12px;
}

.title{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}

.amount{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey500;
  margin-bottom: 2px;
}

.totalPrice{
  display: flex;
  align-items: flex-end;
}

.price{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: $black;
}

.dimension{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey500;
}
