@import "src/shared/styles/common";
.wrapper{
  display: flex;
  flex-direction: column;
}

.line{
  height: 1px;
  width: 100%;
  background: $grey200;
}

.buttonWrap{
  width: max-content;
  padding: 16px 24px;

  @include media-sm-down{
    padding: 16px;
  }
}
