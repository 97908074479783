@import "src/shared/styles/common";

.wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}

.buttonWrapper{
  @include media-sm-down {
    margin-top: 12px;
  }
}
.text{
  margin-right: 8px;
}
