@import "src/shared/styles/common";


%textDefault{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.select{
  &:global(.MuiInputBase-root){
    @extend %textDefault;
    border-radius: 8px;
    color: $black;
    min-height: 36px;
    max-height: 40px;
  }

  &:global(.MuiInputBase-root .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input){
    padding: 12px 32px 12px 9px;
  }

  &:global(.MuiInputBase-root .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.Mui-disabled){
    -webkit-text-fill-color:$black
  }

  &:global(.MuiInputBase-root.Mui-disabled){
    background: $grey100;
    color: $black;
  }

  &:global(.MuiInputBase-root .MuiSelect-select[aria-expanded='true'] ~ fieldset.MuiOutlinedInput-notchedOutline){
    border:1px solid $green300;
  }
  &:global(.MuiInputBase-root.Mui-focused .MuiSelect-select ~ fieldset.MuiOutlinedInput-notchedOutline ){
    border:1px solid $green300;
  }
}


.error{
  &:global(.MuiInputBase-root .MuiSelect-select ~ fieldset.MuiOutlinedInput-notchedOutline){
    border:1px solid $red;
  }
}

.placeholder{
  @extend %textDefault;
  color: $grey500;
  height: auto;
  min-height: 1.4375em;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.menu{
  &:global(.MuiPaper-root){
    border-radius: 8px;
    box-shadow: none;
    border: 1px solid $grey300;
    z-index: 200;
  }

  &:global(.MuiPaper-root .MuiList-root){
    padding: 0;
  }
}
.menuItem{
  &:global(.MuiButtonBase-root){
    @extend %textDefault;
    display: flex;
    align-items: center;
    padding: 9px 8px;
    z-index: 9999999;

    &:global(.MuiMenuItem-root.Mui-selected){
      background: transparent;
    }

    &:global(.MuiMenuItem-root:hover){
      background: transparent;
    }
  }
}

.groupName{
  &:global(.MuiListSubheader-root){
    @include fontFamily(Rubik);
    padding: 15px 8px 3px 8px;
    color: $black;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
  }
}

.iconWrapper{
  position: absolute;
  background: transparent;
  border: none;
  color: $grey500;
  display: flex;
  align-items: center;
  cursor: pointer;
  right: 12px;
}

.cursorDefault{
  cursor: auto;
}

.isSelectedAll{
  &:global(.MuiButtonBase-root){
    background: $grey200;
  }
  &:global(.MuiButtonBase-root:hover){
    background: $grey200 !important;
  }
}
