@import "src/shared/styles/common";

.spinnerWrapper{
  min-width: 318px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @include media-sm-down{
   min-height: 270px;
  }
}

.wrapper{
  width: 100%;
  height: 100%;
}
.title{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $black;
  margin: 0 0 16px 0;
}

.label{
  font-weight: 500;
}

.line{
  height: 1px;
  width: 100%;
  background: $grey200;
}

.positive{
  color: $green300;
}
.negative{
  color: $red;
}

.buttonWrap{
  width: max-content;
}

.selectsGroup{
  display: grid;
  grid-template-columns: repeat(2, minmax(146px, 150px));
  grid-gap: 16px;
  margin-bottom: 16px;

  @include media-md-down{
    grid-template-columns: repeat(2, 1fr);
  }

  @include media-sm-down{
    grid-template-columns: repeat(2, 146px);
  }
}

.productOverview{
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
}
