@import "src/shared/styles/common";

.container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.navigation{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $black;
  margin-right: 32px;
  &:hover{
    color: $green300;
  }
  &[aria-current="page"] {
    color: $green300;
    font-weight: 500;
  }
}

.gap{
  gap: 8px;
}

.icon{
  background: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: $black;
}
