@import "src/shared/styles/common";

.content{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.container{
  display: flex;
  padding: 0 24px;
  gap: 16px;
}

.logo{
  height: 55px;
  object-fit: cover;
}

.buttonWrapper{
  padding: 16px 24px 0 24px;
  display: flex;
  gap: 12px;

}

.wrapper{
  display: flex;
  flex-direction: column;

}

.title{
  font-family: Rubik, sans-serif;
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  margin: 0 0 16px 0;
  padding: 0 24px;
}

.line{
  width: 100%;
  height: 1px;
  background: $grey200;
}


.button{
  &:global(.MuiButton-root) {
    width: min-content;
  }
}
