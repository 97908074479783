@import "src/shared/styles/common";

.wrapper{
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 8px;
  padding: 8px 16px 8px 8px;
}

.content{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px 8px;
}

.title{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $black;
  margin: 0;
}

.circle{
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: $grey200;
}
.iconWrap{
  display: flex;
  align-items: center;
  width: 24px;
  height: 24px;
}

.description{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $black;

}

.buttonGroup{
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 12px;
}
