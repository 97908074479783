@import "src/shared/styles/common";

%textDefault{
  @include fontFamily(Rubik);
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
  color: $black;
}

.wrapper{
  display: flex;
  position: relative;
  width: 1040px;
  overflow-x: auto;
  margin-top: 16px;

  &:after{
    content:'';
    position: absolute;
    height: 1px;
    width: 100%;
    background: $grey200;
    bottom: 45%;
    z-index: 10;
  }
}

.noSellBuy{
  &:after{
    content:'';
    bottom: 55%;
  }
}

.sellBuy{
  &:after{
    content:'';
    bottom: 39%;
  }
}

.weekWrap{
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  width: 100%;
}


.planningForecast{
  margin-top: 32px;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 200px;

}

.label{
  @extend  %textDefault;
  font-weight: 400;
}

.bold{
  font-weight: 500;
}

.marginTop{
  margin-top: 8px;
}
