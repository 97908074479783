@import "src/shared/styles/common";

$cornerPaddingSum: 32px;

.wrapper{
  top: 0;
  position: sticky;
  z-index: 1;
}

.content{
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 14px 16px 10px 16px;
}

.adminContent{
  padding: 0;
  height: 60px;
  align-items: center;
}
.logo{
  width: 140px;
  height: 36px;
  object-fit: cover;
  margin-right: 16px;
}
.adminLogo{
  height: 45px;

}

.container{
  display: flex;
  justify-content: center;
  align-items: center;
}


.dropDownWrapper{
  margin-right: 32px;
}



.labelNavigation{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: inherit;
  cursor: pointer;
}



.innerContent{
  max-width: calc($appMediumWidth + $cornerPaddingSum );
}

.loginNavigation{
  @include fontFamily(Rubik);
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 8px 20px;
  border-radius: 8px;
  background: $green300;
  min-width: 122px;
}
