@import "src/shared/styles/common";


.label{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: $black;
  margin: 0 0 12px 0;
  padding: 0 24px;

  @include media-sm-down {
    padding: 0 16px;
  }
}

.description{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  margin-bottom: 24px;
  padding: 0 24px;

  @include media-sm-down {
    padding: 0 16px;
  }
}

.bold{
  font-weight: 500;
}

.line{
  height: 1px;
  width: 100%;
  background: $grey200;
}

.buttonGroup{
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 12px;
  margin-top: 16px;
  padding: 0 24px;

  @include media-sm-down {
    padding: 0 16px;
  }
}
