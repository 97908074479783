.form{
 display: flex;
 align-items: start;
 flex-direction: column;
}

.buttonGroup{
 width: 100%;
 display: grid;
 grid-gap: 10px;
 margin-top: 16px;
}


