@import "src/shared/styles/common";

.header{
  display: flex;
  flex-direction: column;

  padding: 16px 0 0 24px;

  @include media-sm-down {
    padding-left: 16px;
  }
}

.dateDailyOrder{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}

.titleWrapper {
  display: flex;
  align-items: center;
  gap: 8px;
}

.iconWrap {
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.description{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $black;
  margin-bottom: 8px;
}
