@import "src/shared/styles/common";

.tableWrapper{
  overflow: auto;
}

.iconWrap{
  all: unset;
  cursor: pointer;
  color: $grey500;
}

.table{
  width: 1052px;

  @include media-sm-down {
    width: 1068px;
  }
  table tbody tr td{
    height: 50px;
    padding: 0 !important;
    border-bottom: 1px solid $grey200 !important;
  }

}

.text{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  padding-right: 8px;
}

.status{
  border-radius: 4px;
  padding: 5px 8px;
}

.bold{
  font-weight: 500;
}

.negotiation{
  background: $orange100;
  color: $brown;
}

.published{
  background: $green150;
  color: $green600;
}

.unPublished{
  background: $pink100;
  color:$red500;
}

.sold{
  background: $blue100;
  color: $blue500;
}

.partiallySold{
  background: $blue100;
  color: $blue500;
}

.buttonGroup{
  display: flex;
  gap: 6px;
  align-items: center;
}
