@import "../../../../../shared/styles/common";

.modal{
  &:global(.MuiDialog-root .paperModal.MuiPaper-root){
    overflow: hidden;
    max-width: 1150px;
  }
}

.title{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: $black;
  margin: 0 44px 8px 0;
  padding-left: 24px;

  @include media-sm-down {
   padding-left: 16px;
  }
}

.label{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $black;
  margin-bottom: 16px;
  padding: 0 24px;

  @include media-sm-down {
    padding: 0 16px;
  }
}

.line{
  height: 1px;
  width: 100%;
  background: $grey200;
}

.buttonGroup{
  display: flex;
  gap: 12px;
  padding: 16px 24px 0 24px;

  @include media-sm-down {
    padding: 16px 16px 0 16px;
  }
}

.editButtonWrap{
  width: max-content;
}

.cancelButtonWrap{
  width: max-content;
}
