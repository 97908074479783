@import "src/shared/styles/common";

.modal{
  &:global(.MuiDialog-root .MuiDialog-container){
    @include media-sm-down {
      align-items: start;
    }
  }
}

.infoWrapper{
  padding: 0 24px;

  @include media-sm-down {
    padding: 0 16px;
  }
}

.label{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
}

.value{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $black;
  margin-top: 4px;
}

.buttonGroup{
  padding: 16px 24px 0 24px;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 12px;

  @include media-sm-down {
    padding: 16px 16px 0 16px;
  }
}


.line{
  height: 1px;
  width: 100%;
  background: $grey200;
}


.marginBottom{
  margin-bottom: 16px;
}

.marginTop{
  margin-top: 16px;
}


.title{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: $black;
  padding: 0 24px;
  margin: 0 16px 16px 0;

  @include media-sm-down {
    padding: 0 16px;
    font-size: 20px;
    line-height: 28px;
  }
}

.spinnerWrapper{
  min-height: 402px;
  display: flex;
  justify-content: center;
  align-items: center;
}
