@import "src/shared/styles/common";

%textDefault{
  @include fontFamily(Rubik);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  color: $black;
}

.heading{
  margin-bottom: 8px;
}

.content{
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.dashboardInfo{
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title{
  @include fontFamily(Rubik);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  color: $black;
}

.description{
  @extend  %textDefault;
}

.navigationWrap{
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.navigationText{
  @extend  %textDefault;
}
