@import "../../../../../shared/styles/common";

.line{
  height:1px;
  width: 100%;
  background: $grey200;

  padding: 0 24px;

  @include media-sm-down {
    padding: 0 16px;
  }
}

.body{
  margin-top: 12px;

  @include media-sm-down {
    margin-top: 16px;
  }
}


.headerLabel{
  padding: 16px 24px 0 24px;

  @include media-sm-down {
    padding: 16px 16px 0 16px;
  }
}

.label{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $black;
  margin-top: 12px;
  margin-bottom: 16px;
  padding: 0 24px;

  @include media-sm-down {
    padding: 0 16px;
  }

}
