@import "src/shared/styles/common";

.wrapper{
  display: grid;
  grid-template-columns: repeat(3,minmax(160px, 260px));
  gap: 24px;
  margin-bottom: 24px;

  @include media-sm-down{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.label{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: $black;
}

.date{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}
