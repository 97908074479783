@import "../../../../../shared/styles/common";


.label{
  padding: 16px 24px;

  @include media-sm-down {
    padding: 16px;
  }
}
