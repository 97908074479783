@import './fonts';
@import "colors";
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


html, body, #root {
  height: 100%;
  width: 100%;


}


ul {
  list-style: none;
}

a {
  text-decoration: none;
}


::-webkit-scrollbar {
  border: none;
  height: 8px;
  width: 8px;
}

::-webkit-scrollbar-thumb {
  background-color:$grey300;
  border-radius: 4px;
}

#hubspot-messages-iframe-container {
  z-index: 100 !important;

}

#hubspot-messages-iframe-container.widget-align-right{
  bottom: 40px !important;
}
