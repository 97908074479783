@import "src/shared/styles/common";


%textDefault{
  @include fontFamily(Rubik);
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}


.planGroup{
  padding-top: 8px;
  display: flex;
  width: 100%;
  @include media-sm-down {
    flex-direction: column;
    padding-top: 0;
  }
}

.productGroup{
  display: flex;
}

.inputGroup{
  display: grid;
  grid-template-columns: minmax(170px, 1fr) auto;
  grid-column-gap: 32px;
  align-items: flex-start;

  @include media-sm-down {
    margin-top: 16px;
    grid-column-gap: 24px;
    margin-left: 32px;
  }
}

.amountWrapper{
  display: flex;
  flex-direction: column;
}

.amountLabel{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  margin-bottom: 12px;
}

.amountDescription{
  @extend %textDefault;
  font-weight: 500;
}


.iconArrow{
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  margin-right: 8px;
  transform: rotate(90deg);
}

