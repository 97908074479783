.title{
  margin-bottom: 8px;
}

.buttonGroup{
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 16px;
  margin-top: 24px;
}


.spinnerWrap{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
