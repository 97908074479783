@import "src/shared/styles/common";

.detailsLabel{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;

  @include media-sm-down {
    margin-top: 8px;
  }

}

.marginTop{
  margin-top: 8px;
}
