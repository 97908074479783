@import "src/shared/styles/common";


.formLabel{
  &:global(.MuiFormControlLabel-root){
    margin:  0;
  }

  &:global(.MuiFormControlLabel-root .MuiTypography-root){
    @include fontFamily(Rubik);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $black;
    margin-left: 4px;
  }
}

.checkBox{
  &:global(.MuiCheckbox-root){
    width: 20px;
    height: 20px;
  }

  &:global(.MuiCheckbox-root .MuiSvgIcon-root){
    width: 21px;
    height: 21px;
  }
}
