@import "src/shared/styles/common";

.dateCalendar{
  color: $white;
}

.label{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  margin-bottom: 4px;
}

.buttonWrap{
  all: unset;
  cursor: pointer;
  border: 1px solid $grey300;
  border-radius: 8px;
  background: $white;
  display: flex;
  align-items: center;
  width: 100%;
}

.title{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  padding:9px 12px;
  width: 100%;
}

.iconWrap{
  display: flex;
  align-items: center;
  padding-right: 7px;
}

.active{
  border:1px solid $green300;
}
