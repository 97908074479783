@import "src/shared/styles/common";

.wrapper{
  display: flex;
  flex-wrap: wrap;
  gap: 4px 8px;
  margin-top: 4px;
}

.text{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: $black;
}


.discount{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: $green300;
  border: 1px solid $green300;
  padding: 6px 8px;
  display: flex;
  align-items: center;
  border-radius: 8px;
}

.icon{
  display: flex;
  align-items: center;
  margin-right: 6px;
}

.totalCHF{
  margin-left: 8px;
}
