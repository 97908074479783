@import "src/shared/styles/common";

.detailsWrapper{
  display: grid;
  grid-row-gap: 12px;


  @include media-sm-down {
    margin-left: 0
  }
}

