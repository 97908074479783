
.button{
  &:global(.MuiButtonBase-root){
    padding: 5px 10px;
    font-size: 14px;
    line-height: 22px;
  }

}

.primaryButton{
  &:global(.MuiButtonBase-root){
    width: 75px;
  }
}
.dangerousButton{
  &:global(.MuiButtonBase-root){
    width: 62px;
  }
}
