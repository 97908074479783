@import "src/shared/styles/common";

%textDefault{
  @include fontFamily(Rubik);
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
  color: $black;
  font-weight: 400;
}

.wrapper{
  border-right: 1px solid $grey200;
  padding-left: 8px;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.noBorder{
  border-right:none;
}

.label{
  @extend %textDefault;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}
.text{
  @extend %textDefault;
}

.forecastText{
  @extend %textDefault;
  font-weight: 500;
  margin-top: 8px;
}

.bold{
  font-weight: 500;
}


.positive{
  color: $green300;
}
.negative{
  color: $red;
}

.buttonWrap{
  width: max-content;
}
