@import "src/shared/styles/common";
.wrapper{
  padding: 0 24px;

  @include media-sm-down{
    padding: 0 16px;
  }
}
.line{
  height: 1px;
  width: 100%;
  background: $grey200;
}

.label{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: $black;
  margin: 0 0 8px;
}

