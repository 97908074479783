@import "src/shared/styles/common";

%textDefault{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  padding-right: 8px;
}

.tableWrapper{
  overflow: auto;
}

.buttonGroup{
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap:10px ;
}

.productCell{
  width: 110px;
}

.amountTuHeader{
  width: 120px;
}

.buyerCell{
  width: 70px;
}

.tableHeader{
  @extend %textDefault;
  font-weight: 500;

}

.tableCell{
  @extend %textDefault;
}

.status{
  border-radius: 4px;
  padding: 5px 8px;
}

.bold{
  font-weight: 500;
}

.table{
  width: 1052px;

  @include media-sm-down {
    width: 1068px;
  }

  table tbody tr td{
    height: 50px;
    padding: 0 !important;
    border-bottom: 1px solid $grey200 !important;
  }

}

.statusHeader{
  width: 114px;
}

.button{
  &:global(.MuiButton-root) {
    padding: 5px 10px;
    font-size: 14px;
    line-height: 22px;
  }
}

.negotiating{
  background: $orange100;
  color: $brown;
}
.confirmed{
  background: $blue100;
  color: $blue500;
}

.declined{
  background: $pink100;
  color:$red500;
}
