@import "src/shared/styles/common";

.tableWrapper{
  margin-bottom: 16px;
  overflow: auto;
}
.tableHeader{
  @include fontFamily(Rubik);
  margin-bottom: 8px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: $black;
}

.table{
  width: 1052px;
  @include media-sm-down {
    width: 1068px;
  }

  table tbody tr td:first-child{
    width: 400px;
  }
}
.productInfoWrapper{
  display: flex;
  gap: 8px;
  padding-right: 16px;
}
.image{
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 4px;
}
.content{
  display: flex;
  flex-direction: column;
}

.cellText{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
}

.alignRight{
  text-align: right;
}
