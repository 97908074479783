.container{
  display: flex;
}

.label{
  display: flex;
  align-items: center;
  margin-left: 8px;
}

