@import "../../../../../shared/styles/common";

 %totalPriceDefaultText{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}

.wrapper{
  padding: 0  24px 16px 24px;

  @include media-sm-down {
    padding: 0  16px 16px 16px;
  }

}
.minPriceInputLabel{
  width: 180px;
}

.minPriceInput{
  &:global(.MuiFormControl-root .MuiInputBase-root) {
    width: 160px;
  }

  &:global(.MuiFormControl-root .MuiInputBase-root .MuiInputBase-input){
    width: 160px;
  }
}
.title{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: $black;
  margin: 0 0 8px 0;
}


.body{
  display: grid;
  grid-template-columns: repeat(3, 160px);
  grid-gap: 16px 32px;

  @include media-sm-down {
    grid-template-columns: 160px;
  }
}

.footer{
  margin-top: 16px;
}

.label{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 8px;
}

.discount{
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 16px;
}


.priceWrapper{
  display: flex;
  flex-wrap: wrap;
  gap: 8px 32px;
}

.discountInput{
  width: 60px;
  margin: 0 8px;
}

.amountHighWrapper{
  display: flex;
  align-items: flex-start;
}

.amountHighInput{
  min-width: 80px;
  max-width: 80px;
  width: 80px;
  margin-left: 8px;

}

.amountPrice{
  display: flex;
  align-items: center;
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  word-break: break-word;
}

.totalPrice{
  @extend %totalPriceDefaultText;
}

.totalPriceDiscount{
  @extend %totalPriceDefaultText;
  word-break: break-word;
}

.discountAmount{
  color: $green300;
}
