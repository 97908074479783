.wrapper{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.inActive{
  padding: 16px 8px;
}

.active{
  padding: 16px 16px 0 8px;
}

.quantities{
  padding: 12px 12px 12px 8px;
}

.content{
  display: flex;
  width: 100%;
}
