@import "src/shared/styles/common";


%textDefault{
  @include fontFamily(Rubik);
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  color: $black;
}

.wrapper{
  padding: 0 10px;
  border-right: 1px solid $grey200;;
}


.noBorderRight{
  border-right: none
}

.label{
  @extend %textDefault;
  font-weight: 500;
  margin: 4px 0 2px;
}

.header{
  display: flex;
  flex-direction: column;
}

.description{
  @extend %textDefault;
  font-weight: 400;
  margin-bottom: 8px;
}

.stockText{
  @extend %textDefault;
  font-weight: 400;
  background: $grey100;
  border: 1px solid $green300;
  border-radius: 4px;
  padding: 2px 8px;
  margin-bottom: 5px;
  align-self: start;
  width: min-content;
}

.stockTextError {
  border-color: $red;
}

.textItalic{
  @include fontFamily(Rubik);
  font-style: italic;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $black;
}

.marginTop{
  margin-top: 4px;
}

.order{
  margin: 16px 0;
}

.body{
  padding-bottom: 16px;
}

.textField{
  &:global(.MuiFormControl-root .MuiInputBase-root) {
    height: 32px;
    width: 100px;
    background: $white;
  }

  &:global(.MuiFormControl-root .MuiInputBase-root .MuiInputBase-input){
    height: 32px;
    width: 100px;
  }
}

.textFieldLabel{
 @extend %textDefault;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
}

.newDailyOrder{
  background: rgba(62, 146, 72, 0.1);
}

.waitingForConfirmation {
  background: $orange100;
}

.confirmed {
  background: $green150;
}

.pastDate {
  background: $grey100;
}