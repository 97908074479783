@import "src/shared/styles/common";

.wrapper{
  position: relative;
  padding: 16px 0;
  width: 100%;
}

.iconWrapper{
  all: unset;
  position: absolute;
  top: 12px;
  right: 12px;
  color: $black;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.label{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: $black;
  margin: 0 0 12px 0;
  padding: 0 24px;

  @include media-sm-down {
    padding: 0 16px;
  }
}

.description{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  margin-top: 12px;
  margin-bottom: 24px;
  padding: 0 24px;

  @include media-sm-down {
    padding: 0 16px;
  }
}

.bold{
  font-weight: 500;
}

.line{
  height: 1px;
  width: 100%;
  background: $grey200;
}

.buttonGroup{
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 12px;
  margin-top: 16px;
  padding: 0 24px;

  @include media-sm-down {
    padding: 0 16px;
  }
}

.title{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: $black;
  padding: 0 24px;
  margin: 0;

  @include media-sm-down {
    padding: 0 16px;
  }
}

.line{
  height: 1px;
  width: 100%;
  background: $grey200;
}
.bold{
  font-weight: 500;
}

.spinnerWrapper{
  min-height: 166px;
  display: flex;
  justify-content: center;
  align-items: center;
}
