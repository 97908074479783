@import "src/shared/styles/common";

.title{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: $black;
  margin: 0 0 8px 0;
}

.text{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: $black;
  margin-top: 48px;
}

.productsWrap{
  display: flex;
  flex-wrap: wrap;
  gap: 24px 16px;
}
