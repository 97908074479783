@import "src/shared/styles/common";

%textDefault{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}

.wrapper{
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px 16px 16px 8px;
}

.quantities{
  padding: 12px 16px 12px 8px;
}

.content{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 4px;
}

.productName{
  @extend %textDefault;
  font-weight: 500;
  margin: 0 8px 0 0;
}

.circle{
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: $grey200;
  margin-right: 8px;
}

.label{
  @extend %textDefault;
  font-weight: 400;
}

.empty{
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey500;
}

.error{
  color: $red;
}
