@import "src/shared/styles/common";

.actionsWrap{
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
}


%textDefault{
  @include fontFamily(Rubik);
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  color: $black;
}

.tableHeader{
  @extend %textDefault;
  font-weight: 500;
}

.tableBody{
  @extend %textDefault;
  font-weight: 400;
}


.tableWrapper{
  overflow: auto;
  min-height: 570px;
}

.table{

  table tbody{
    margin-top: 10px !important;
  }
  table tbody tr td{
    &:first-child{
      width: 80%;
    }
  }

  table tbody tr td{
    height: 50px;
    padding: 0 !important;
    border-bottom: 1px solid $grey200 !important;
  }


}

.deactivated{
  text-transform:lowercase;
  color:$red;
}


.button{
  &:global(.MuiButtonBase-root){
    padding: 5px 10px;
    font-size: 14px;
    line-height: 22px;
    width: min-content;
  }
}
