@import "src/shared/styles/common";

.wrapper{
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  margin-bottom: 16px;

  @include media-sm-down{
    padding: 0 16px;
  }
}

.label{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: $black;
  margin: 0 0 8px;
}


.title{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $black;
  margin-bottom: 4px;
}

.container{
  display: flex;
  flex-wrap: wrap;
  gap: 32px;
}

.description{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;

}

.noAddressText{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey500;
}
