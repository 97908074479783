@import "../../../styles/common";

.tabs{
  &:global(.MuiTabs-root  .MuiTabs-scroller .MuiTabs-flexContainer){
    gap: 24px;
  }

  &:global(.MuiTabs-root .MuiTabs-scroller .MuiTabs-indicator){
    background-color:$green300;
    height: 3px;
    z-index: 60;
  }
}
