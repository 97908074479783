@import "src/shared/styles/common";

.wrapper{
  width: 100%;
}

.form{
  display: flex;
  flex-direction: column;
}

.content{
  display: grid;
  grid-template-columns: minmax(250px,552px) 1fr;
  grid-column-gap: 32px;
  margin-bottom: 16px;
  padding: 0 24px;

  @include media-sm-down {
    grid-template-columns: 1fr;
    grid-gap: 16px;
    padding: 0 16px;
  }
}

.textAreaWrapper{
  width: 100%;
  max-width: 390px;
  min-width: 160px;
  height: 152px;
}

.label{
  padding: 16px 0 0 24px;

  @include media-sm-down {
    padding-left: 16px;
  }
}

.fieldGroup{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap:16px 32px;

  @include media-sm-down {
    grid-template-columns: 1fr;
  }
}

.datePickerWrapper{
  width: 130px;
}

.group{
  grid-column: 1/3;
  display: grid;
  grid-template-columns:minmax(auto, 260px) minmax(180px, 1fr);
  grid-gap:0 32px;

  @include media-sm-down {
    grid-column: 1;
    grid-gap:0 16px;
  }
}

.line{
  width: 100%;
  height: 1px;
  background: $grey200;
}

.buttonGroup{
  padding: 16px 24px;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 16px;

  @include media-sm-down {
    padding: 16px;
  }
}

.errorText{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ED0B0B;
  margin-top: 4px;
}
