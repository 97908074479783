@import "src/shared/styles/common";

.content{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.container{
  display: flex;
  @include media-md-down{
    flex-direction: column;
  }
}

.logo{
  height: 40px;
  object-fit: cover;
}

.buttonWrapper{
  width:max-content;
  margin-top: 8px;
}
