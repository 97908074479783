@import "src/shared/styles/common";

.wrapper{
  margin-top: 8px;
}



.totalContent{
  display: flex;
  justify-content: space-between;
  margin-top: 8px;
}
.title{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: $black;
}

.content{
  display: grid;
  grid-template-columns: minmax(130px, 392px) 1fr 1fr;
  gap: 8px;
  margin-bottom: 8px;

  @include  media-md-down{
    grid-template-columns: max-content max-content 1fr;
  }
}

.serviceFee{
  display: flex;
  flex-direction: column;
  gap: 8px;
  @include  media-md-down{
    margin-right: 16px;
  }
}


.totalPrice{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}

.middleContent{
  display: flex;
  flex-direction: column;
  gap: 8px;
}


.middleContentText{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
}

.serviceFeeText{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
}

.bold{
  font-weight: 500;
}

.line{
  height: 1px;
  width: 100%;
  background: $grey200;
}

.description{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $black;
  margin: 16px 0;
}

.navigation{
  color: $green300;
  &:hover{
    color: $green400;
  }
}

.green{
  color: $green300;
}
