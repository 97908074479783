@import "src/shared/styles/common";
@import "../../../../../features/growingPlan/BaseGrowingPlanWorkShop/components/GrowingPlanWorkShopTable/GrowingPlanWorkShopTable.module.scss";
.wrapperTest{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.content{
  position: relative;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @include media-sm-down{
    padding-bottom: 6px;
  }
}

.overlay{
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: $white;
  opacity: 0.7;
  z-index: 10;
}
.spinnerWrap{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
