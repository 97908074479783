@import "src/shared/styles/common";

.wrapper{
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: $black;
  margin: 0;
}
