@import "src/shared/styles/common";

.wrapper{
  margin-right: 32px;
}

.content{
  display: flex;
  align-items: center;
}

.navigation{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $black;

  &:hover{
    color: $green300;
  }
  &[aria-current="page"] {
    color: $green300;
    font-weight: 500;
  }
}

.icon{
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: $grey500;
}


.menuItem{
  &:global(.MuiButtonBase-root){
    padding: 9px 8px;
    min-width: 140px;
  }

}

.active{
  color: $green300;
}
