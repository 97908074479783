@import "src/shared/styles/common";

.wrapper{
  display: flex;
  align-items: center;
}

.iconWrap{
  display: flex;
  align-items: center;
  color: $red;
}

.message{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color:$black;
  margin-left: 8px;
}

