@import "src/shared/styles/common";


.wrapper{
  display: flex;
  align-items: flex-start;

  @include media-sm-down{
    flex-direction: column;
    gap: 12px;
  }
}

.content{
  display: flex;
  align-items: flex-start;
}

.inputWrapper{
  width: 80px;
}

.center{
  align-self: center;
}

.circle{
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: $grey200;
  margin: 18px 8px  0 8px;
}


.text{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  margin: 10px  8px 0 0;
  align-self: flex-start;
}

.textBold{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: $black;
  margin: 0 8px 0 0;
}



