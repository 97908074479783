@import "src/shared/styles/common";

.bidWrapper{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  margin-bottom: 16px;
  gap:8px 0;
}



.textBold{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: $black;
}

.inputWrapper{
  width: 80px;
}

.textWrap{
  display: flex;
  align-items: center;
}


.navigation{
  @include fontFamily(Rubik);
  text-align: center;
  color: $white;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 8px 20px;
  border-radius: 8px;
  background: $green300;
}
