@import "src/shared/styles/common";

.label{

  &:global(.MuiFormControlLabel-root){
    align-items: start;
    justify-content: start;
    margin: 0;
    width: 100%;
    height: 100%;
  }

  &:global(.MuiFormControlLabel-root .MuiTypography-root){
    @include fontFamily(Rubik);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $black;
    margin-bottom: 4px;
  }


  &:global(.MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled ){
    color: $black;
  }
}
