@import 'src/shared/styles/common';

.wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.textArea{
  @include fontFamily(Rubik);
  resize: none;
  background: $white;
  border: 1px solid $grey300;
  border-radius: 8px;
  width: 100%;
  height: 100% !important;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  padding: 9px 12px;

  &:focus{
    outline: none !important;
    border: 1px solid $green300;
  }
}

.error{
  border: 1px solid $red;
}

.errorMessage{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $red;
  margin: 2px 0 0 0;
}

