@import "src/shared/styles/common";

.tableWrapper{
  margin-top: 12px;
}

.tableHeader{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $black;
  padding-right: 8px;
  width: 100%;
  min-width: 60px;
  max-width: 168px;
}

.tableValue{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
}

.textBold{
  font-weight: 500;
}

.tableWrapper{
  padding-left: 8px;
}

.iconsContainer{
  display: flex;
  gap: 8px;
}

.iconWrapper{
  all: unset;
  cursor: pointer;
  color: $grey500
}
