@import "src/shared/styles/common";

.tableWrapper{
  overflow: auto;
}

.table{
  width: 1052px;
  min-height: 500px;

  @include media-sm-down {
    width: 1068px;
  }
  table tbody tr td{
    height: 50px;
    padding: 0 !important;
    border-bottom: 1px solid $grey200 !important;
    div{
      padding-right:  8px;
    }
  }
}

.noTable{
  width: 1052px;
  min-height: 500px;
}

%textDefault{
  @include fontFamily(Rubik);
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0;
  color: $black;
}

.tableHeader{
  @extend %textDefault;
  font-weight: 500;
}

.tableBody{
  @extend %textDefault;
  font-weight: 400;
}

.actionsWrap{
  display: flex;
  align-items: center;
  gap:12px;
  justify-content: flex-start;
  margin-left: 8px;
}

.actions{
  margin-left: 8px;
  text-align: left;
}



.noData{
  @include fontFamily(Rubik);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  margin-top: 48px;

}

.allUsersActions{
  table tbody tr td{
    &:first-child{
      width: 20%;
    }
    &:nth-child(2){
      width: 20%;

    }

    &:nth-child(3){
      width: 20%;
      div{
        padding-right:  8px;
      }

    }
    &:nth-child(4){
      width: 15%;
    }

    &:nth-child(5){
      width: 15%;
    }

    &:nth-child(6){
      width: 13%;
    }
  }
}

.newRegistrationActions{
  table tbody tr td{
    &:first-child{
      width: 20%;
    }
    &:nth-child(2){
      width: 15%;
    }

    &:nth-child(3){
      width: 25%;

    }
    &:nth-child(4){
      width: 20%;
    }

    &:nth-child(5){
      width: 20%;
    }
  }
}

.deactivatedActions{
  table tbody tr td{
    &:first-child{
      width: 20%;
    }
    &:nth-child(2){
      width: 20%;
    }

    &:nth-child(3){
      width: 25%;
    }
    &:nth-child(4){
      width: 20%;
    }
    &:nth-child(5){
      width: 15%;
    }

  }
}

.rejectedActions{
  table tbody tr td{
    &:first-child{
      width: 20%;
    }
    &:nth-child(2){
      width: 20%;
    }

    &:nth-child(3){
      width: 25%;
    }
    &:nth-child(4){
      width: 20%;
    }
    &:nth-child(5){
      width: 15%;
    }

  }
}


