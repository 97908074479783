
@import "src/shared/styles/common";

.wrapper{
  display: flex;
  flex-direction: column;
}

.content{
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 0 24px 16px 24px;
}




.title{
  @include fontFamily(Rubik);
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
}

.description{
  @include fontFamily(Rubik);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
}

.bold{
  font-weight: 500;
}

.line{
  height: 1px;
  width: 100%;
  background: $grey200;
}

.buttonGroup{
  display: grid;
  grid-template-columns: 120px 90px;
  gap: 12px;
  padding: 16px 24px 0 24px;
}
