@import "src/shared/styles/common";

.marginBottom{
  margin-bottom: 8px
}

.label{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $black;
  margin:0;
}


.value{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey500;
}


.productInfoWrap{
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
}

.producerInfoText{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  margin-left: 6px;
}

.producerInfoWrap{
  display: flex;
  align-items: center;
  padding: 5px 8px;
  background: $grey200;
  border-radius: 8px;
  width: max-content;
}

