@import "src/shared/styles/common";

.spinnerWrap{
  display: flex;
  justify-content: center;
  align-items: center;
}

.wrapper{
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.noDataText{
  @include fontFamily(Rubik);
  font-size: 16px;
  line-height: 24px;
  color: $grey500;
  padding-bottom: 16px;
}
