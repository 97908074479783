@import "src/shared/styles/common";

.wrapper{
  width: 100%;
}

.line{
  height: 1px;
  width: 100%;
  background: $grey300;
}



.buttonGroup{
  margin: 16px 24px;
  width: max-content;
  @include media-sm-down {
    margin: 16px;
  }
}
