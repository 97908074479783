@import "../../../../shared/styles/common";

.selectWrapper{
  width: 100%;
  max-width: 124px;

  @include media-sm-down{
    width: auto;
    min-width: 60px;
  }
}


.languageSelect{
  &:global(.MuiInputBase-root .MuiSelect-select~ fieldset.MuiOutlinedInput-notchedOutline){
    border: none;
  }

  @include media-sm-down {
    &:global(.MuiInputBase-root .MuiSelect-select div >p){
      display: none;
    }
  }

  &:global(.MuiInputBase-root .MuiSelect-select div >p){
    @include fontFamily(Rubik);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-left: 8px;
  }

  &:global(.MuiInputBase-root .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input){
    padding: 6px 4px 6px 8px;

  }

  &:global(.MuiInputBase-root >button){
    right: 4px;
  }

  &:global(.MuiInputBase-root .MuiPopover-root .MuiPaper-root .MuiList-root){
    width: 120px;
  }
}
