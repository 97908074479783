@import "src/shared/styles/common";


.label{
  &:global(.MuiFormLabel-root){
    @include fontFamily(Rubik);
    color: $black;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 4px;
    letter-spacing: 0;

    &:global(.MuiFormLabel-root.Mui-focused){
      color: $black;
    }
  }
}

.formControlLabel{
  &:global(.MuiFormControlLabel-root .MuiTypography-root){
    @include fontFamily(Rubik);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    letter-spacing: 0;
    color: $black;
  }
  &:global(.MuiFormControlLabel-root .MuiFormControlLabel-label.Mui-disabled){
    opacity: 0.5;
    color: $black;
  }


}

.radioGroup{
  &:global(.MuiFormGroup-root){
    gap: 8px;
    margin-left: 4px;
  }
}

.radio{
  &:global(.MuiButtonBase-root){
    padding: 0 8px;
    color: $grey300;
  }
}

.errorText{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #ED0B0B;
  margin-top: 4px;
}

