@import "../../../../../shared/styles/common";

.wrapper{
  padding-left: 24px;
  margin-bottom: 8px;
  overflow: auto;


  @include media-sm-down {
    padding-left: 16px;
  }
}

.tableContent{
  width: 100%;

  @include media-sm-down {
    width: 876px;
  }
}


.tableHeader{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $black;
  margin-bottom: 6px;
}
