@import "src/shared/styles/common";

.label{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: $black;
  margin-bottom: -8px;
  padding: 16px 24px 0 24px;
  @include media-sm-down {
    padding: 16px 16px 0 16px;
  }
}
