@import "src/shared/styles/common";

.title{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $black;
  margin: 0 0 16px 0;
}

.navigationWrapper{
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding-bottom: 8px;
}

.navigationTitle{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
}

.descriptionWrapper{
  margin-bottom: 24px;
}

.navigation{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $green300;
  &:hover{
    color: $green400;
  }
}

.description{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
}

.descriptionFaq{
  display: flex;
}
