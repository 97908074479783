@import "src/shared/styles/common";

.label{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: $black;
  margin: 0 0 16px 0;
  padding: 0 24px;

  @include media-sm-down {
    padding: 0 16px;
  }
}

.description{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  margin-bottom: 8px;
  padding: 0 24px;

  @include media-sm-down {
    padding: 0 16px;
  }
}

.radioWrapper{
  margin-bottom: 24px;
  padding: 0 20px;

  @include media-sm-down {
    padding: 0 12px;
  }
}

.line{
  height: 1px;
  width: 100%;
  background: $grey200;
}


.buttonGroup{
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 12px;
  padding: 16px 24px 0 24px;

  @include media-sm-down {
    padding: 16px 16px 0 16px;
  }
}

.textarea{
  margin-top: 4px;
  height: 100px;
}

.button{
  &:global(.MuiButton-root) {
    padding: 5px 13px;
    font-size: 14px;
    line-height: 22px;
  }
}
