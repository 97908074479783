@import "src/shared/styles/common";

%textDefault{
  @include fontFamily(Rubik);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  color:#000;
}

.tab{
  display: flex;
  align-items: center;
  gap: 4px;
}

.tabText{
  @extend  %textDefault;
}

.circle{
  @extend  %textDefault;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  background: $green300;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
}

.tabsWrapper{
  margin-bottom: -1px;
}

.line{
  position: relative;
  height: 1px;
  margin-top: -2px;
  background: $grey200;
  z-index: 50;
}
