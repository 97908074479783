@import "src/shared/styles/common";

.container{
  display: flex;
  flex-direction: column;
}

.input{
  display: none;
}

.title{
  @include fontFamily(Rubik);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  margin-bottom: 4px;
}

.label{
  @include fontFamily(Rubik);
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $black;
  padding: 8px 20px;
  border-radius: 8px;
  background: $grey200;
  cursor: pointer;
  max-width: 118px;
  &:hover{
    background: $grey250;
  }
}

.errorMessage{
  @include fontFamily(Rubik);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $red;
  margin-top: 2px;
}
