@import "src/shared/styles/common";

.label{
  padding: 16px 24px 0 24px;

  @include media-sm-down {
    padding: 16px 16px 0 16px;
  }
}

.fieldGroup{
  display: grid;
  grid-gap: 16px;
  padding: 0 24px 16px 24px;

  @include media-sm-down {
    padding: 0 16px 16px 16px;
  }
}

.inputTitleWrapper{
  max-width: 260px;
  @include media-sm-down {
    max-width: none;
  }
}

.textareaWrapper{
  width: 100%;
  max-width: 552px;
  height: 122px;
}

.buttonGroup{
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 12px;
  padding: 16px 24px;

  @include media-sm-down {
    padding: 16px
  }
}

.line{
  height: 1px;
  width: 100%;
  background: $grey200;
}
