@import "src/shared/styles/common";

.navigation{
  display: flex;
  align-items: center;
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $green300;

  &:hover{
    color: $green400;
  }

}

.iconWrapper{
  display: flex;
  align-items: center;
  margin-left: 8px;
  color: inherit;
}
