.wrapper{
  width: 100%;
  height: 100%;
}

.spinnerWrapper{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
