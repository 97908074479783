@import "src/shared/styles/common";

.wrapper{
  padding: 0 16px 24px;
  display: flex;
  gap: 16px 24px;

  @include media-md-down{
    flex-direction: column;
  }

  @include media-sm-down{
    padding: 0 0 16px 16px;
    flex-direction: column;
  }
}

.imageWrapper{
  position: relative;
  width: 294px;
  height: 180px;

  @include media-sm-down{
    width: 312px;
    height: 192px;
  }
}
.productImage{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
}

.logo{
  width: 42px;
  height: 42px;
  border-radius: 50%;
  position: absolute;
  bottom: 6px;
  right: 6px;
}


.title{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: $black;
  margin: 0;
}

.description{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  margin-bottom: 16px;
}

.infoWrapper{
  display: flex;
  gap: 16px 24px;

  @include media-md-down{
    flex-direction: column;
  }
}
