@import "src/shared/styles/common";

.spinnerWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.line{
  width: 100%;
  height: 1px;
  background: $grey200;
}

.errorWrap{
  width: 100%;
  height: 100%;
}

.errorMessage{
  @include fontFamily('Rubik');
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  margin-top: 48px;
}
