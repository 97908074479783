@import "src/shared/styles/common";

.wrapper{
  display: flex;
  flex-direction: column;
}

.content{
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.logo{
  height: 40px;
  object-fit: cover;
}

.label{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  margin: 0;
}

.iconWrapper{
  display: flex;
  align-items: center;
  background: transparent;
  border: none;
  margin-left: 8px;
  cursor: pointer;
}
