@import 'src/shared/styles/common';

.accordion {
  border: 1px solid #BFBFBF;
  border-radius: 8px;
  padding: 16px 16px 16px 8px;
  overflow: auto;

  @include media-sm-down {
    padding: 16px 8px
  }
}

.accordionSummary{

  @include media-sm-down {
    &:global(.MuiAccordionSummary-root){
      align-items: start;
    }
  }

  &:global(.MuiAccordionSummary-root){
    flex-direction: row-reverse;
    min-height: auto;
    padding: 0;
  }
  &:global(.MuiAccordionSummary-root.Mui-focusVisible){
    background: transparent;
  }

  &:global(.MuiAccordionSummary-root .MuiAccordionSummary-content){
    margin: 0;
  }
}

.accordionDetails{
  &:global(.MuiAccordionDetails-root){
    padding: 0;
  }
}

.iconWrapper{
  display: flex;
  justify-content: center;
  margin-right: 8px;
}


.iconTransform{
  transform: rotate(90deg);
}

.error {
  border-color: $red;
}

.paddingSmall{
  &:global(.MuiAccordion-root){
    padding: 8px 16px 8px 8px;

    @include media-sm-down {
      padding: 16px 16px 16px 8px;
    }
  }

  &:global(.MuiAccordion-root.Mui-expanded){
    padding: 8px 16px 16px 8px;

    @include media-sm-down {
      padding: 16px 8px;
    }
  }
}

.noPadding{
  &:global(.MuiAccordion-root){
    padding: 0;


    @include media-sm-down {
      padding: 0;

    }
  }

  &:global(.MuiAccordion-root.Mui-expanded){
    padding: 0;

    @include media-sm-down {
      padding: 0;
    }
  }
}
