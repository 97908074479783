@import "src/shared/styles/common";



.line{
  height: 1px;
  width: 100%;
  background: $grey200;
}


.buttonGroup{
  margin: 16px 24px;
  display: flex;
  justify-content: start;
  gap: 6px 12px;
  flex-wrap: wrap;

  @include media-sm-down {
    margin-left: 16px;
    margin-right: 0;
  }
}

.button {
  width: max-content !important;
}

.buttonGroupEdit{
  @include media-sm-down {
    flex-direction: column;
  }
}

.spinnerWrapper{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
