@import "src/shared/styles/common";

%textDefault{
  @include fontFamily(Rubik);
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
}

.content{
  all: unset;
  cursor: pointer;
  color: $black;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  border: 1px solid transparent;
}

.bordered{
  border: 1px solid $green300;
  border-radius: 8px;
}

.menuItem{
  &:global(.MuiButtonBase-root){
    padding: 0 !important;
    min-width: 240px;
    @include media-md-down{
      min-width: 100%;
    }
  }

}
.active{
  color: $green300;
}

.menuItemContent{
  display: flex;
  flex-direction: column;
  width: 100%;
}

.line{
  height: 1px;
  width: 100%;
  background:$grey200;
}

.contentGroup{
  display: flex;
  align-items: flex-start;
  gap: 8px;
  padding: 10px 24px;
}

.active{
  padding: 10px 24px 10px 10px;
}

.title{
  @extend %textDefault;
  font-weight: 400;
  color: $grey500;
  margin-bottom: 2px;
}

.bell{
  width: 6px;
  height: 6px;
  margin-top: 5px;
  background: $green300;
  border-radius: 50%;
}

.author{
  @extend %textDefault;
  font-weight: 500;
  color: $black;
}

.date{
  @extend %textDefault;
  font-weight: 400;
  color: $black;
}


.menuListRoot{

  &:global(.MuiList-root){
    padding-top: 0;
    padding-bottom: 0;
    background: $white;
    border: 1px solid $grey200;
    border-radius: 4px;
    width: max-content;
    margin-top: 2px;
    overflow-y:auto;
    max-height: 300px;
  }

  &:global(.MuiList-root .MuiButtonBase-root){
    @include fontFamily(Rubik);
    padding: 9px 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $black;


    &:active{
      background: transparent;
    }
  }
}

.large{
  &:global(.MuiList-root){
    width: 100%;
  }
}

.popover{
  width: 100%;
}

.menuItemNoData{
  &:global(.MuiButtonBase-root){
    padding: 0 !important;
    width: 220px;
    word-break: break-all;
    @include media-md-down{
      min-width: 100%;
    }
  }
}

.noNotification{
  @include fontFamily(Rubik);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: $grey500;
  padding: 10px;
  width: 220px;
  @include media-md-down{
    width: 100%;
  }
}
