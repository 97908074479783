@import "src/shared/styles/common";

.spinnerWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.wrapper{
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;

  @include media-sm-down {
    margin-bottom: 24px;
  }
}

.selectWrap{
  max-width: 260px;
  @include media-sm-down {
    max-width: none;
    width: 100%;
  }
}

.content{
  display: flex;
  flex-wrap: wrap;
  gap: 16px 24px;
  @include media-sm-down {
    gap: 16px;
  }
}

.label{
  @include fontFamily(Rubik);
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.selectGroup{
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 16px;
}
