@import "src/shared/styles/common";

%navigationDefault{
  @include fontFamily(Rubik);
  display: flex;
  align-items: center;
  color: $black;
  height:44px;
  width: 100%;
  padding-left: 16px;
  &[aria-current="page"] {
    border-left: 4px solid $green300;
    background-color: $grey200;
  }
}

.drawer{
  padding: 10px 0;
  width: 100%;
}

.navigationContainer{
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
}


.navigationMain{
  @extend %navigationDefault;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.navigation{
  @extend %navigationDefault;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}
