@import "src/shared/styles/common";

$gap:48px;

.spinnerWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.wrapper{
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: $gap;

  @include media-md-down{
    grid-template-columns: 1fr;
    grid-gap:0;
  }
}

.content{
  display: flex;
  position: relative;

  & + &{
    &:before{
      content:'';
      position: absolute;
      height: 100%;
      width: 1px;
      background: $grey200;
      left: -$gap/2;

      @include media-md-down{
        height: 1px;
        width: 100%;
        position: static;
        margin: 32px 0;
      }
    }
  }

  @include media-md-down{
    flex-direction: column;
  }
}

.label{
  margin-bottom: 8px;
}

.line{
  position: absolute;
  height: 100%;
  width: 1px;
  background: $grey200;
  right: -$gap/2;

  @include media-md-down{
    height: 1px;
    width: 100%;
    position: static;
    margin: 24px 0;
  }
}
