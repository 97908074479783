@import "src/shared/styles/common";

%textDefault{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  color: $black;
}

.card{
  padding: 16px 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $grey200;
  border-radius: 8px;
  background: $white;

}

.product{
  @extend %textDefault;
  text-align: center;
}

.label{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $grey500;
}

.quantityWrap{
  @extend %textDefault;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.unit{
  color: $grey500;
  margin-left: 4px;
}
