@import "src/shared/styles/common";

.formFields{
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-content: start;
  padding: 0 24px;
  gap: 16px 24px;
  margin-bottom: 24px;
}
