@import "src/shared/styles/common";

%textDefault{
  @include fontFamily(Rubik);
  font-style: normal;
  font-size: 14px;
  line-height: 22px;
  color: $black;
}

.wrapper{
  display: flex;
  align-items: center;
}

.label{
  @extend %textDefault;
  font-weight: 400;
  margin-right: 4px;
}

.description{
  @extend %textDefault;
  font-weight: 500;
}
