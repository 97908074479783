@import "src/shared/styles/common";

.button{
  &:global(.MuiButtonBase-root){
    width: max-content;
  }
}

.formTitle{
  @include fontFamily(Rubik);
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0;
  text-align: left;
  margin: 0 0 16px 0;
  padding: 0 24px;
}

.line{
  width: 100%;
  height: 1px;
  background: $grey200;
}

.buttonGroup{
  display: flex;
  padding: 16px 24px 0 16px;
  gap: 12px;
}


.button{
  &:global(.MuiButtonBase-root){
    width: max-content;
  }
}
