@import "src/shared/styles/common";

%textDefault{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
}

.tableWrapper{
  overflow: auto;
}

.table{
  width: 1052px;

  @include media-sm-down {
    width: 1068px;
  }
  table tbody tr td{
    height: 50px;
    padding: 0 !important;
    border-bottom: 1px solid $grey200 !important;
  }

}

.product{
  min-width: 185px;
}

.label{
  min-width: 96px;
}
.amountTu{
  min-width: 92px;
}
.totalPrice{
  min-width: 86px;
}
.orderDate{
  min-width: 116px;
}
.statusHeader{
  width: 105px;
}

.tableHeader{
  @extend %textDefault;
  font-weight: 500;
  margin-right: 72px;
}
.noMarginRight{
  margin-right: 0;
}


.text{
  @extend %textDefault;
  padding-right: 8px;
}

.status{
  border-radius: 4px;
  padding: 5px 8px;
}


.negotiating{
  background: $orange100;
  color: $brown;
}

.declined{
  background: $pink100;
  color: $red500;
}

.confirmed{
  background: $blue100;
  color: $blue500;
}
