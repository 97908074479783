@import "../../../../../shared/styles/common";

.buttonWrap{
  width: max-content;
  margin: 16px 24px;

  @include media-sm-down {
    margin: 16px;
  }
}


.buttonGroup{
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 12px;
  margin-top: 16px;
  padding: 0 24px 16px 24px;

  @include media-sm-down {
    padding: 0 16px 16px 16px;
  }
}

.line{
  height: 1px;
  width: 100%;
  background: $grey200;
}
