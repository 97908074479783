@import 'src/shared/styles/common';

.iconWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: $green300;
  color: $white;
  width: 32px;
  height: 32px;
  border-radius: 8px;
  border: none;

  @include media-sm-down {
    align-self: center;
  }
}

.buttonWrap{
  margin-left: 16px;
}

.noMargin{
  @include media-sm-down {
    margin: 0 ;
  }
}