@import "../../styles/common";

.wrapper{
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 48px;
}

.title{
  @include fontFamily(Rubik);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  margin-bottom: 16px;
}
