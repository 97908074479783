@import "src/shared/styles/common";

.notification{
  &:global(.Toastify__toast-container){
    width: 343px;
    bottom: 16px;
    padding: 0;
    right: 80px;
    z-index: 2147483647;
  }

  &:global(.Toastify__toast-container .Toastify__toast){
    box-shadow: none;
    padding: 8px;
    border-radius: 3px;
    margin-bottom: 0;
    margin-top: 16px;
    min-height: auto;
  }


  &:global(.Toastify__toast-container .Toastify__toast .Toastify__toast-body){
    padding: 0;
  }

  &:global(.Toastify__toast-container .Toastify__toast.success){
    background: $green50;
    color: $black;
    border: 1px solid $green300
  }

  &:global(.Toastify__toast-container .Toastify__toast.error){
    background: $red300;
    border: 1px solid red;
  }

  &:global(.Toastify__toast-container .Toastify__toast.error #crossIcon){
    display: flex;
    align-items: center;
  }
}
