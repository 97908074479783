@import "src/shared/styles/common";

.wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}


.content{
  display: flex;
  width: 100%;
}

.small{
  max-width: 308px;

  @include media-sm-down {
    max-width: 343px;
  }
}

.medium{
  max-width: 600px;
}

.large{
  max-width: 100%;
}

.maxHeight{
  height: 100%;
}
