@import "src/shared/styles/common";

.dangerousButton{
  &:global(.MuiButtonBase-root){
    background: $red;
    color: $white;
    width: 100%;

    &:disabled{
      background: $pink;
      color: $white;
    }

    &:hover{
      color: $white;
      background: $red200;
    }

  }
}
