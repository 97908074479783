@import "src/shared/styles/common";

.wrapper{
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-gap: 12px;
  margin-bottom: 24px;
}

.iconWrapper{
  all: unset;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: $grey500;
}
