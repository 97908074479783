@import "../../../../../shared/styles/common";

.wrapper{
  padding: 0  24px;
  margin-bottom: 16px;
  @include media-sm-down {
    padding: 0  16px;
  }

}
.title{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: $black;
  margin: 0 0 8px 0;
}

.navigation{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: $green300;
}

.radioGroup{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px 32px;
}

.publishRadioWrapper{
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.content{
  display: grid;
  grid-template-columns: repeat(3, minmax(150px,260px));
  grid-gap: 16px 32px;

  @include media-sm-down {
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}


.pickerGroup{
  width: 200px;
}

.chipWrap{
  & >div{
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
  }
}
