@import "src/shared/styles/common";

.tag {
  padding: 2px 8px;
  border-radius: 4px;
  width: max-content;
  @include fontFamily(Rubik);
  font-size: 14px;
  line-height: 22px;
}

.draft {
  background: $blue100;
  color: $blue500;
}

.confirmed {
  background: $green150;
  color: $green600;
}

.waitingForReview {
  background: $orange100;
  color: $brown;
}

.dateFromPaste {
  background: $grey200;
}