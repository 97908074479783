@import "src/shared/styles/common";

.wrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px 8px;
  border: 1px solid $grey300;
  background: $white;
  width: 100%;
  max-width: 245px;
  cursor: pointer;
  border-radius: 3px;

  &:hover{
    border: 1px solid $green300;
    background: $green50;
  }

  @include media-sm-down {
    min-width: 100%;
  }
}

.product{
  @include fontFamily(Rubik);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  color: $black;
}

.label{
  @include fontFamily(Rubik);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: $grey500;
}

.info{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  @include fontFamily(Rubik);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.unit{
  @include fontFamily(Rubik);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: $grey500;
  margin-top: 4px;
}

.bold{
  font-weight: 500;
}

