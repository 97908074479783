@import "src/shared/styles/common";

.wrapper{
  display: flex;
  align-items: center;
  gap: 8px;
}

.textWrap{
  @include fontFamily(Rubik);
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.label{
  font-weight: 400;
  margin-left: 2px;
}
