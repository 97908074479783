@import "../../../../../shared/styles/common";

.wrapper{
  padding: 0 24px;
  margin-bottom: 16px;
  @include media-sm-down {
    padding: 0 16px;
  }

}

.imageFileContent{
  align-items: start;

  & > img{
    width: 293px;
    height: 180px;
    object-fit: cover;
    border-radius: 8px;

    @include media-sm-down {
      width: 283px;
      height: 176px;
    }
  }

}

.title{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: $black;
  margin: 0 0 8px 0;
}

.inputGroup{
  display: grid;
  grid-template-columns: repeat(3, minmax(130px, 260px));
  grid-gap: 32px;
  margin-bottom: 16px;

  @include media-sm-down {
    grid-template-columns:1fr;
    grid-gap: 16px;
  }
}

.datePickerGroup{
  margin: 16px 0;
  display: grid;
  grid-template-columns: repeat(2, 130px);
  grid-gap: 32px;
}

.textareaWrapper{
  width: 100%;
  max-width: 552px ;
  height: 126px;

  @include media-sm-down {
    height: 166px;
  }
}
