@import "src/shared/styles/common";

.viewWrapper{
  display: flex;
  align-items: center;
  gap: 4px;
}

.viewText{
  @include fontFamily(Rubik);
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
}

.buttonGroup{
  display: flex;
  gap: 4px;
  align-items: center;
}
.slash{
  @include fontFamily(Rubik);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey500;
}

.toggleButton{
  all: unset;
  @include fontFamily(Rubik);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey500;
  cursor: pointer;
}

.active{
  color: $black;
  font-weight: 400;
  border-bottom: 1px solid $black;
}
