@import "src/shared/styles/common";

.list{
  padding: 0 8px 16px 8px;
}

.listItem{
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.circle{
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: $grey200;
}

.label{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}

.description{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}
