@import "src/shared/styles/common";

%textLabelDefault{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}

.accordionWrapper{
  margin-bottom: 12px;
  display: grid;
  grid-row-gap: 12px;
}


.labelValid{
 @extend %textLabelDefault;
  margin-bottom: 16px;

  @include media-sm-down {
    margin-bottom: 8px;
  }
}

.labelNotValid{
  @extend %textLabelDefault;
  margin: 32px 0 12px 0;
}

