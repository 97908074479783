.wrapper{
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
}

.spinnerWrap{
  display: flex;
  justify-content: center;
  align-items: center;
}
