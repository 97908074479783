@import "src/shared/styles/common";

.wrapper{
  display: flex;
  flex-direction: column;
  padding:16px 24px;


  @include media-sm-down{
    padding:16px;
  }
}

.description{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $green300;
  margin-bottom: 8px;
}


.buttonWrap{
  width: max-content;
}
