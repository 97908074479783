@import "src/shared/styles/common";

.spinnerWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.header{
  margin-bottom: 8px;
}

.content{
  min-height: 500px;
}

.text{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: $black;
  margin-top: 48px;

}
