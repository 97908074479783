@import "src/shared/styles/common";

.menuListRoot{

  &:global(.MuiList-root){
    padding-top: 0;
    padding-bottom: 0;
    background: $white;
    border: 1px solid $grey200;
    border-radius: 4px;
    width: max-content;
    margin-top: 2px;
  }

  &:global(.MuiList-root .MuiButtonBase-root){
    @include fontFamily(Rubik);
    padding: 9px 8px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $black;

    &:active{
      background: transparent;
    }
  }
}

.small{
  &:global(.MuiList-root){
    width: max-content;
  }
}
