@import "src/shared/styles/common";
.textField{
  &:global(.MuiFormControl-root) {
    margin-top: 0;
    width: 100%;
  }

  &:global(.MuiFormControl-root .MuiInputBase-root) {
    @include fontFamily(Rubik);
    color: $black;
    height: 40px;
    border-radius: 8px;
    padding: 0 7px 0 0;
  }

  &:global(.MuiFormControl-root .MuiInputBase-root .MuiInputAdornment-root .MuiButtonBase-root){
    &:hover{
      background: transparent;
    }
  }

  &:global(.MuiFormControl-root .MuiInputBase-input.Mui-disabled){
    -webkit-text-fill-color: $black;
    background: $grey100;
  }

  &:global(.MuiFormControl-root .Mui-error .MuiOutlinedInput-notchedOutline){
    border-color: $red;
    border-width: 1px;
  }

  &:global(.MuiFormControl-root .Mui-focused  .MuiOutlinedInput-notchedOutline ){
    border-color: $green300;
    border-width: 1px;
  }
  &:global(.MuiFormControl-root .Mui-disabled .MuiOutlinedInput-notchedOutline){
    border-color: $grey300;
    border-width: 1px;
  }

  &:global(.MuiFormControl-root .MuiInputBase-root .MuiInputBase-input){
    height: 40px;
    padding: 0 0 0 9px;
    text-transform: lowercase;
  }

  &:global(.MuiFormControl-root .MuiFormHelperText-root) {
    @include fontFamily(Rubik);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $red;
    margin: 2px 0 0 0;
  }

  &:global(.MuiFormControl-root .MuiInputBase-root .MuiInputAdornment-root .MuiButtonBase-root ){
    padding: 8px 0;
    margin-right: 0;
  }
  &:global(.MuiFormControl-root .MuiInputBase-root .MuiInputAdornment-root ){
    margin-left: 0;
  }
}

.label{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  margin-bottom: 4px;
}
