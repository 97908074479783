@import "src/shared/styles/common";

%textDefault{
  @include fontFamily(Rubik);
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}

.wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;

  @include media-sm-down {
    align-items: start;
    flex-direction: column;
  }
}

.label{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 4px;
}

.titleWrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}

.title{
  @extend %textDefault;
  font-weight: 500;
  margin-right: 8px;
}

.text{
  @extend %textDefault;
  font-weight: 400;
  margin-right: 8px;
}

.circle{
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: $grey200;
  margin-right: 8px;
}

.buttonWrap{
  @include media-md-down {
    margin-top: 8px;
  }
}
