@import "src/shared/styles/common";

.iconWrap{
  all: unset;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  max-width: 32px;
  max-height: 32px;
  padding: 5px;
  border-radius: 8px;
  gap: 10px;
  background: $grey200;
  color:$black;

  &:hover{
    color:$black;
    background: $grey300;
  }
}

.formTitle{
  @include fontFamily(Rubik);
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0;
  text-align: left;
  margin: 0 0 16px 0;
  padding: 0 24px;
}

.buttonGroup{
  display: flex;
  padding: 16px 24px 0 16px;
  gap: 12px;
}

.button{
  &:global(.MuiButtonBase-root){
    width: max-content;
  }
}

.line{
  width: 100%;
  height: 1px;
  background: $grey200;
}
