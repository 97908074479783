@import "src/shared/styles/common";

.wrapper{
  display: flex;
  flex-direction: column;
}
.select{
  &:global(.MuiInputBase-root .MuiPopover-root .MuiPaper-root .MuiList-root .MuiButtonBase-root.Mui-selected){
    background: $grey200;
  }
}

.errorMessage{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $red;
  margin: 2px 0 0 0;
}
