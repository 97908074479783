@import "src/shared/styles/common";
.wrapper{
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 16px 16px 24px 8px;
}

.content{
  display: flex;
  width: 100%;
}

.tagWrapper {
  margin-top: 16px;
}

.formField{
  display: grid;
  grid-template-columns: minmax(200px,260px) minmax(100px, 160px) minmax(100px, 160px) minmax(100px, 160px);
  grid-gap: 16px 32px;
  width: 100%;

  @include media-md-down {
   grid-template-columns: 1fr;
    > div:nth-child(2) {
      order:3;
    }

    > div:nth-child(3) {
      order:5;
    }

    > div:nth-child(4) {
      order:7;
    }

    > div:nth-child(5) {
      order:2;
    }

    > div:nth-child(6) {
      order:4;
    }

    > div:nth-child(7) {
      order:6;
    }
    > div:last-child{
      order: 8;
    }
  }



}

.iconWrap{
  width: 24px;
}


