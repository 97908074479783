@import "src/shared/styles/common";

.tableCell{
  &:global(.MuiTableCell-root){
    @include fontFamily(Rubik);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: $black;
    padding: 0 8px 8px 0;
    min-height: 22px;
    border-bottom:none;
  }
}
