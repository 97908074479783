@import "src/shared/styles/common";

.accordionWrapper{
  margin-bottom: 16px;
  padding: 0 8px;
}

.wrap + .wrap {
  position: relative;
  margin-top: 32px;

  .line {
    position: absolute;
    height: 1px;
    top: -17px;
    left: -8px;
    right: -8px;
    background: $grey300;
  }
}
