@import "src/shared/styles/common";
.wrapper{

}

.line{
  width: 100%;
  height: 1px;
  background: $grey200;
}

.buttonGroup{
  display: grid;
  grid-template-columns: repeat(2, min-content);
  gap: 12px;
  padding: 16px 24px;
  width: 100%;

  @include media-sm-down {
    padding: 16px;
    grid-template-columns: 1fr;
    gap: 8px;
  }

}

.buttonGroupMain{
  width: 100%;
  display: grid;
  gap: 12px;
  grid-template-columns: max-content max-content;
  @include media-sm-down {
    grid-template-columns: max-content;
  }
}

.cancelButtonWrap{
  width: max-content;
}
