@import "src/shared/styles/common";


.form{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 16px 32px;
  align-items: start;

  @include media-md-down{
    grid-template-columns: 1fr;

    :nth-child(3){
      grid-row: 2;
    }

    :nth-child(5){
      grid-row: 3;
    }

    :nth-child(7){
      grid-row: 4;
    }

    :nth-child(9){
      grid-row: 5;
    }

    :nth-child(11){
      grid-row: 6;
    }
  }
}

.inputGroup{
  display: grid;
  grid-template-columns: 84px 1fr;
  grid-column-gap: 16px;
  align-items: flex-start;
}

.title{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: $black;
  margin-top: 26px;

  @include media-md-down{
    margin-top: 0;
    margin-bottom: -8px;
  }
}

.formFooter{
  grid-column: 1/3;
  height: 100%;
  @include media-md-down{
    grid-column:1
  }
}

.text{
  @include fontFamily(Rubik);
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $grey500;
  margin-top: 16px;
}


.navigateSignUp{
  font-weight: 500;
  color: $green300;
  margin-left: 4px;
}

.checkBoxContainer{
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}


.label{
  @include fontFamily(Rubik);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  margin-left: 4px;
}

.buttonWrap{
  margin-top: 24px;
  width: max-content;
}

.navigateTerms{
  color: $green300;
  &:hover{
    color: $green400;
  }
}
