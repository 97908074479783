@import "src/shared/styles/common";

.form{
  width: 100%;
}

.title{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: $black;
  margin: 0 0 12px;
  padding: 0 24px;

  @include media-sm-down {
    padding: 0 16px;
  }
}

.label{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  margin-bottom: 16px;
  padding: 0 24px;

  @include media-sm-down {
    padding: 0 16px;
  }
}

.inputGroup{
  display: grid;
  grid-template-columns: 260px 1fr;
  padding: 0 24px;
  margin-bottom: 24px;
  width: 100%;
  grid-gap: 32px;

  :last-child{
    &:global(.MuiFormControlLabel-root .MuiInputBase-root){
      width: 84px;
    }
  }

  @include media-md-down {
    padding: 0 16px;
    grid-template-columns: 1fr;
    grid-gap: 16px;
  }
}

.buttonGroup{
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: 12px;
  padding: 16px 24px 0 24px;

  @include media-sm-down {
    padding: 16px 16px 0 16px;
  }
}

.line{
  height: 1px;
  width: 100%;
  background: $grey200;
}

.modal{
  &:global(.MuiDialog-root .paperModal.MuiPaper-root){
    max-width: 602px;
  }
}
