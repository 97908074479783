@import "src/shared/styles/common";


.wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}


.card{
  padding: 32px 24px;
  box-shadow: 0 4px 20px 0 #75757533;
  border: 1px solid $green300;

}

.title{
  @include fontFamily(Rubik);
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
  color: $black;
  margin: 0 0 8px 0;
}

.description{
  @include fontFamily(Rubik);
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: $black;
}
