@import "../../../../../shared/styles/common";

.wrapper{
  padding: 0 24px;

  @include media-sm-down {
    padding: 0 8px;
  }
}

.tableWrapper{
  margin-bottom: 16px;
}


.lineBold{
  height: 1px;
  width: 100%;
  background: $grey300;
}

.marginTop{
  margin-top: 24px;
}
