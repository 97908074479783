@import "src/shared/styles/common";

.wrapper{
  display: flex;
  flex-direction: column;
  gap:16px
}


.checkBoxWrap{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px 24px;

  @include media-sm-down{
    align-items: flex-start;
    flex-direction: column;
  }

}
