@import "src/shared/styles/common";

.spinnerWrapper{
  min-width: 318px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  @include media-sm-down{
    min-height: 235px;
  }
}

.wrapper{
  width: 100%;
  height: 100%;
}

.title{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $black;
  margin: 0 0 16px 0;
}
.weekPickerWrap{
  width: 318px;

  @include media-md-down{
    width: 100%;
  }
}

.reports{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 8px;
  margin: 16px 0 24px 0;
}

.noReports{
  display: block;
  width: 100%;
}

.buttonWrap{
  width: max-content;
}

.noWeeklyReportText{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey500;

}
