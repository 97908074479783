@import "src/shared/styles/common";

.wrapper{
  all: unset;
  display: flex;
  align-items: center;
  gap: 8px;
  padding:16px 24px;
  cursor: pointer;

  @include media-sm-down{
    padding:16px;
  }
}

.noPadding{
  padding: 0;
}
.iconWrap{
  display: flex;
  align-items: center;
 color: $grey500;
}

.headerText{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $grey500;
}
