@import "src/shared/styles/common";

.label{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $black;
  margin:0;
}

.productInfoWrap{
  margin-bottom: 8px;
}
