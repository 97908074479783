@import "src/shared/styles/common";

.selectWrap {
  display: flex;
  align-items: center;
  gap: 8px;
}
.iconWrap{
  all: unset;
  margin-top: 30px;
  cursor: pointer;
  color: $black;
}

.select{
  width: 200px;
  @include  media-md-down{
    width: 100%;
  }
}
