@import "src/shared/styles/common";

.wrapper{
  padding: 16px 24px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  border: 1px solid $grey200;
  width: 100%;
  max-width: 334px;
}

.labelWrap{
  @include fontFamily(Rubik);
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 24px;
  gap: 4px;
  margin-bottom: 16px;
}

.description{
  font-weight: 400;
}

.label{
  font-size: 16px;
  margin: 0;
  font-weight: 500;
}

.line{
  height: 1px;
  width: 100%;
  background: $grey200;
}

.positive{
  color: $green300;
}
.negative{
  color: $red;
}

.productInfoWrap{
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
}
