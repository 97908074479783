@import "src/shared/styles/common";

.wrapper{
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 16px;
}

.selectsWrapper{
  display: grid;
  grid-template-columns: repeat(3, 260px);
  grid-gap: 24px;

  @include media-sm-down{
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
}

.label{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: $black;
}

.date{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}

.contentWrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
