@import "src/shared/styles/common";

.wrapper{
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  padding: 0 24px;

  @include media-sm-down {
    grid-template-columns: 1fr;
    gap: 16px;
    padding: 0 16px;
  }
}

.content{
  display: grid;
  grid-gap: 16px;
}

.headerContent{
  display: grid;
  grid-template-columns: 1fr auto;
  grid-column-gap: 32px;

  @include media-sm-down {
    grid-gap: 16px;
    grid-template-columns: 1fr;
  }
}
.datePickerWrapper{
  width: 130px;
}

.bodyContent{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 32px;

  @include media-sm-down {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
  }
}

.group{
  display: grid;
  grid-template-columns: 154px 90px;
  grid-column-gap: 16px;
  align-items: start;

  @include media-sm-down {
    grid-template-columns: 200px;
    grid-column-gap: 0;
  }
}

.amountGroup{
  @include media-sm-down {
    display: grid;
    margin-top: 16px;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 16px;
  }
}

.textarea{
  width: 100%;
  height: 148px;
}
