
.button{
  &:global(.MuiButtonBase-root){
    padding: 5px 10px;
    font-size: 14px;
    line-height: 22px;
    width: 75px;
  }

}
