@import "../../../../../shared/styles/common";

.wrapper{
  display: grid;
  grid-gap: 32px;
  grid-template-columns: 260px min-content 1fr;
  padding: 0 24px 16px 24px;

  @include media-md-down {
    padding: 0 16px 16px 16px;
    grid-gap: 16px;
    grid-template-columns: 1fr;
  }
}

.noGrowingPlan{
  grid-gap: 24px;
  grid-template-columns: 260px 1fr;
  @include media-md-down {
    grid-template-columns: 1fr;
    padding: 0 16px 16px 16px;
    grid-gap: 16px;
  }

}


.group{
  display: grid;
  grid-gap: 32px;
  grid-template-columns: min-content 132px;

  @include media-md-down {
    grid-template-columns: min-content 1fr;
  }

}

.noGrowingPlanWrap{
  display: grid;
  grid-template-rows: 1fr min-content;
  gap: 12px;
  width: 100%;

  @include media-md-down {
    grid-template-rows: 1fr;
  }
}

.viewToggleWrap{
  padding-right: 24px;
  justify-self: end;
}

.selectGroup{
  display: grid;
  grid-gap: 16px 24px;
  grid-template-columns: repeat(3, auto);

  @include media-md-down {
    grid-template-columns: 1fr
  }
}

.selectDateGroup{
  display: grid;
  grid-template-columns: 100px 132px;
  gap: 24px;

  @include media-md-down {
    grid-template-columns: 100px 1fr;
  }
}


.yearWrapper{
  display: flex;
  flex-direction: column;
}

.label{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  margin-bottom: 12px;
}

.description{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}
