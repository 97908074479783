@import "src/shared/styles/common";

.pagination{
  &:global(.MuiPagination-root .MuiPaginationItem-root){
    width: 32px;
    height: 32px;
    padding: 5px 10px;
    background: $grey200;
    border-radius: 8px;
    border: none;
  }

  &:global(.MuiPagination-root .MuiPaginationItem-root.Mui-selected){
    background: $green300;
    color: $white;

    &:hover{
      background: $green400;
    }
  }
}
