@import "src/shared/styles/common";

.customCheckBox{
  &:global(.MuiCheckbox-root){
    padding: 0;
  }

  &:global(.MuiCheckbox-root .MuiSvgIcon-root){
    color: $grey300;
  }
  &:global(.MuiCheckbox-root.Mui-checked .MuiSvgIcon-root){
    color: $green300;
  }
}
