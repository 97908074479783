@import "src/shared/styles/common";

.wrapper{
  border-top: 1px solid $grey200;
}

.stockGroup{
  display: grid;
  grid-template-columns: repeat(7, 154px);
  overflow: auto;
}
