@import "src/shared/styles/common";

.wrapper{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  @include media-sm-down {
    flex-direction: column;
    align-items: start;
  }
}

.buttonWrapper{

  @include media-sm-down {
    margin-top: 12px;
  }
}
