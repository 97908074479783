@import "src/shared/styles/common";

.button {
  width: max-content !important;
}

.buttonGroup{
  border-top: 1px solid $grey200;
  padding: 16px 24px;
  display: flex;
  justify-content: start;
  gap: 8px 12px;
  flex-wrap: wrap;

  &:empty {
    display: none;
  }

  @include media-sm-down {
    margin-left: 16px;
    margin-right: 0;
  }
}

.buttonGroupEdit{
  @include media-sm-down {
    flex-direction: column;
  }
}