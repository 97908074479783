@import "../../../../../../shared/styles/media";

.wrapper{
  width: 260px;
  margin-right: 32px;

  @include media-sm-down {
    width: 228px;
    margin-right: 12px;
  }
}
