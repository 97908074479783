@import "src/shared/styles/common";

$cornerPaddingSum: 24px;

%textDefault{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
}


.wrapper{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 8px  8px  8px  16px;


  @include media-sm-down {
    padding: 9px  8px  9px  16px;
  }
}

.content{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 586px;

  @include media-sm-down {
    flex-direction: column;
    align-items:start;
    & > p {
      order: 1;
    }
  }
}

.label{
  @extend %textDefault;
}

.navigationContainer{
  display: flex;
  @include media-sm-down {
    margin-bottom: 8px;
  }
}

.navigate{
  @extend %textDefault;

  &:hover{
    color: $green300;
  }

  &:not(:last-child) {
   margin-right: 32px;
  }
}


.innerContent{
  max-width: calc($appMediumWidth + $cornerPaddingSum );
}

