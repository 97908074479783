@import "src/shared/styles/common";


.line{
  height: 1px;
  width: 100%;
  background: $grey200;
}

.spinnerWrapper{
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
