@import "src/shared/styles/common";



.iconWrap{
  all: unset;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  max-width: 32px;
  max-height: 32px;
  padding: 5px;
  border-radius: 8px;
  gap: 10px;
  background: $grey200;
  color:$black;

  &:hover{
    color:$black;
    background: $grey300;
  }
}

.active{
  color:$black;
  background: $grey300;
}

.menuItem{
  &:global(.MuiButtonBase-root){
    width: 140px;
  }
}
