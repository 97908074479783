@import "src/shared/styles/common";


.wrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
  gap: 4px;
}

.title{
  font-family: Rubik, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0;
  color: $black;
}
.description{
  font-family: Rubik, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  color: $black;
}

