@import '../../../../../shared/styles/common';


.wrapper{
  display: grid;
  width: 100%;
  max-width: 650px;
  grid-row-gap: 16px;
  padding: 0 24px;

  @include media-sm-down {
    padding: 0 16px;
  }
}

.main{
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 32px;

  @include media-sm-down {
    grid-template-columns: 1fr;
    grid-column-gap: 0;
    grid-row-gap: 16px;
  }
}

.group{
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 16px;
  align-items: start;
}

.textAreaWrapper{
  min-width: 310px;
  height: 148px;
  min-height: 122px;
}


.label{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  margin-right: 8px;
}

.timeInput{
  width: 98px;

  @include media-sm-down {
    margin-top: 4px;
  }
}

.timeContainer{
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  margin-top: 12px;

  @include media-sm-down {
    margin-top: 4px;
    grid-template-columns: 1fr;
  }
}


.line{
  height: 1px;
  width: 100%;
  background: $grey200;
}

.time{
  &:global(.MuiFormControl-root .MuiInputBase-root .MuiInputBase-input) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.errorText{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $red;
  margin-top: 4px;
  grid-column: 2;

  @include media-sm-down {
    grid-column: 1;
  }
}
