@import "src/shared/styles/common";

.iconWrap{
  all: unset;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  max-width: 32px;
  max-height: 32px;
  padding: 5px;
  border-radius: 8px;
  gap: 10px;
  background: $grey200;
  color:$black;

  &:hover{
    color:$black;
    background: $grey300;
  }
}

@import "src/shared/styles/common";

.form{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 16px 32px;
  align-items: flex-start;
  padding: 0 24px;

  :nth-child(7){
    grid-column: 2;

    &:global(.MuiFormControlLabel-root .MuiInputBase-root){
      width: 84px;
    }
  }

  :nth-child(8){
    grid-row: 4;
  }


}

.title{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: $black;
  margin: 0;
  padding: 0 24px 16px 24px;
}

.passwordTitle{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: $black;
  margin: 0;
}

.inputGroup{
  display: grid;
  grid-template-columns: 84px 1fr;
  grid-column-gap: 16px;
  align-items: flex-start;
}
.checkBoxContainer{
  grid-column: 1/3;
  height: 100%;
  display: flex;
  align-items: center;
  @include media-md-down{
    grid-column:1
  }
}

.passwordContainer{
  grid-column: 1/3;
  display: flex;
  flex-direction: column;
  @include media-md-down{
    grid-column:1
  }
}

.passwordInputGroup{
  margin-top: 8px;
  display: flex;
  column-gap: 32px;
  @include media-md-down{
    flex-direction: column;
    column-gap: 0;
    row-gap: 16px;
  }
}
.label{
  @include fontFamily(Rubik);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  margin-left: 4px;
}

.buttonGroup{
  margin-top: 8px;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 16px;

  @include media-md-down{
    grid-column-gap: 12px;
  }
}
