@import "src/shared/styles/common";

.headerWrap{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 8px;
}

.title{
  @include fontFamily(Rubik);
  font-size: 24px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0;
  margin: 0;
}
