@import "../../../styles/common";

.tab{
  &:global(.MuiButtonBase-root){
    @include fontFamily(Rubik);
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0;
    color: #000;
    padding: 0;
    text-transform: unset;
  }


  &:global(.MuiButtonBase-root.Mui-selected){
    font-weight: 500;
    color: #000;
  }
}
