@import "src/shared/styles/common";

%textDefault{
  @include fontFamily(Rubik);
  font-style: normal;
  font-size: 16px;
  line-height: 24px;
  color: $black;
}

.content{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 3px 0;

  @include media-sm-down {
    padding: 0;
  }
}

.titleWrapper{
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  row-gap: 5px;
}

.iconArrow{
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.productLabel{
  @extend %textDefault;
  font-weight: 500;
  color: $black;
  margin-right: 8px;
}

.amountLabelWrapper{
  @extend %textDefault;
  font-weight: 400;
  display: flex;
  align-items: center;
  margin-right: 8px;
}


.inActive{
  color: $grey500;
  font-weight: 400;
}

.circle{
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: $grey200;
  margin-right: 8px;
}

.error{
  color: $red;
}
