@import "src/shared/styles/common";

.form{
  display: grid;
  grid-gap: 16px;
}

.helperText{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $grey500;
  margin-bottom: 16px;
}

.noSpaceText{
  white-space:nowrap;
}
