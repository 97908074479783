@import "src/shared/styles/common";


.tableCell{
  &:global(.MuiTableCell-root){
    @include fontFamily(Rubik);
    padding:0;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    color: $black;
    border-bottom: none;
    position: static;
  }
}
