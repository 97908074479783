@import "src/shared/styles/common";

.placeholder{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $grey500;
  letter-spacing: 0;
  top: -6px;
}

.chipWrapper{
  display: flex;
  flex-wrap: wrap;
  gap: 5px 4px;
  margin-right: 20px;
}

.select{
  &:global(.MuiInputBase-root) {
    max-height: none;

  }
  &:global(.MuiInputBase-root .MuiSelect-select.MuiSelect-outlined.MuiInputBase-input.MuiOutlinedInput-input){
    padding: 9px 9px;
  }

  &:global(.MuiInputBase-root .MuiPopover-root .MuiPaper-root .MuiList-root .MuiButtonBase-root.Mui-selected){
    background: $grey200;
  }
}

.chip{
  &:global(.MuiButtonBase-root.MuiChip-root){
    @include fontFamily(Rubik);
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: $black;
    background: $grey200;
    height: auto;
    &:global(.MuiButtonBase-root.MuiChip-root .MuiChip-label){
      padding: 7px 10px 7px 8px;

    }
    &:global(.MuiButtonBase-root.MuiChip-root span[role='presentation'] .MuiChip-deleteIcon){
      color: $grey500;
    }
  }
}

.wrapper{
  display: flex;
  flex-direction: column;
}

.errorMessage{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: $red;
  margin: 2px 0 0 0;
}

