@import "src/shared/styles/common";


.wrapper{
  margin-top: 24px;
  width: 1026px;

  @include media-sm-down {
    width: 1068px;
  }
}


.table{
  table thead tr {
    th:first-child{
      width: 35%;
    }
  th:nth-child(2){
    width: 14%;
  }
  }

  table  tr {

    &:nth-last-child(2) td{
      border-top: 1px solid $grey200;
      font-weight: 500;
      padding: 16px 0 8px 0;
    }

    &:last-child td{
      border-top: 1px solid $grey200;
      font-weight: 500;
      padding: 16px 0 8px 0;
    }
  }

}

.tableHeader{
  font-weight: 500;
  padding-bottom: 16px;
  border-bottom: 1px solid $grey200;
  margin-bottom: 16px;
}


.growingPlanHeader{
  padding-left: 8px;
}

.unit{
  width: 16%;
}
.weekDay{
  width: 8%
}


.totalWeekValue{
  text-align: center;
}

.paddingLeft{
  padding-left: 8px;
}
