@import "src/shared/styles/common";

%textDefault{
  @include fontFamily(Rubik);
  font-size: 14px;
  font-style: normal;
  line-height: 22px;
}

.wrapper{
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.title{
  @include fontFamily(Rubik);
  color: $black;
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
}

.info{
  display: flex;
  flex-direction: column;
}

.textBold{
  @extend %textDefault;
  font-weight: 500;

  @include media-sm-down {
    padding-bottom: 10px;
  }
}

.text{
  @extend %textDefault;
  font-weight: 400;
}
