@import "../../../../../shared/styles/common";

.bigCalendar{
  &:global(.rbc-calendar) {
    overflow: auto;
  }
  &:global(.rbc-calendar .rbc-month-view) {
    border: none;
    overflow: auto;
    width: 1100px;
  }

  &:global(.rbc-calendar .rbc-month-view .rbc-header) {
    border-left: none;
  }
  &:global(.rbc-calendar .rbc-month-view .rbc-month-row .rbc-row-bg .rbc-off-range-bg){
    background: transparent;
  }

  &:global(.rbc-calendar .rbc-month-view .rbc-month-row .rbc-row-content){
    z-index: 0;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &:global(.rbc-calendar .rbc-month-view .rbc-month-row .rbc-row-bg div[style]){
   background: $grey100 !important;
  }

  &:global(.rbc-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-off-range){
    background: transparent;
  }


  &:global(.rbc-calendar .rbc-month-view .rbc-month-row .rbc-row-bg .rbc-today){
   background: transparent;
  }

  &:global(.rbc-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-now.rbc-current div >p){
    background: $green300;
    border-radius: 50%;
    color: $white;
    width: 22px;
    height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:global(.rbc-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-row-segment .rbc-event){
    background: transparent;
    outline: none;
    cursor: auto;
  }
  &:global(.rbc-calendar .rbc-month-view .rbc-month-row .rbc-row-content .rbc-row .rbc-date-cell){
    text-align: start;
    padding-left: 8px;
    padding-top: 4px;
  }
}

.calendarWrapper{
  @include media-sm-down {
    padding-bottom: 16px;
  }
}

.details{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: $black;
  padding: 5px 8px;
  border: none;
  background: $grey200;
  border-radius: 8px;
  cursor: pointer;
}

.monthHeader{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  text-align: start;
  margin: 0;
}

.buttonsWrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
  position: absolute;
  top: 25%;
}
