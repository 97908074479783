@import 'src/shared/styles/common';

.button {
  &:global(.MuiButton-root) {
    @include fontFamily(Rubik);
    padding: 8px 20px;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    border-radius: 8px;
    text-transform: none;
    box-shadow: none;
    letter-spacing: 0;
    border: none;
    width: 100%;
    &:hover{
      box-shadow: none;
    }
  }
}

