@import "src/shared/styles/common";

.form{
  display: grid;
  grid-template-columns: repeat(2,1fr);
  grid-gap: 16px 32px;
  align-items: flex-start;

  :nth-child(7){
    grid-column: 2;

    &:global(.MuiFormControlLabel-root .MuiInputBase-root){
      width: 84px;
    }
  }

  :nth-child(8){
    grid-row: 4;
  }

  @include media-md-down{
    grid-template-columns: 1fr;
    :nth-child(7){
      grid-column: auto;
    }

    :nth-child(8){
      grid-row: auto;
    }

    :nth-child(8){
      grid-row: 4;
    }

    :nth-child(5){
      grid-row: 3;
    }

    :nth-child(3){
      grid-row: 2;
    }

    :nth-child(9){
      grid-row: 5;
    }

    :nth-child(11){
      grid-row: 6;
    }
  }
}

.title{
  @include fontFamily(Rubik);
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  color: $black;
}

.inputGroup{
  display: grid;
  grid-template-columns: 84px 1fr;
  grid-column-gap: 16px;
  align-items: flex-start;
}
.checkBoxContainer{
  grid-column: 1/3;
  height: 100%;
  display: flex;
  align-items: center;
  @include media-md-down{
    grid-column:1
  }
}

.passwordContainer{
  grid-column: 1/3;
  display: flex;
  flex-direction: column;
  @include media-md-down{
    grid-column:1
  }
}

.passwordInputGroup{
  margin-top: 8px;
  display: flex;
  column-gap: 32px;
  @include media-md-down{
    flex-direction: column;
    column-gap: 0;
    row-gap: 16px;
  }
}
.label{
  @include fontFamily(Rubik);
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: $black;
  margin-left: 4px;
}

.buttonGroup{
  margin-top: 8px;
  display: grid;
  grid-template-columns: max-content max-content;
  grid-column-gap: 16px;

  @include media-md-down{
    grid-column-gap: 12px;
  }
}
