$white: #fff;
$black: #282D28;
$grey100: #F5F5F5;
$grey200: #E7E7E7;
$grey250: #D0D0D0;
$grey300: #BFBFBF;
$grey500: #757575;
$grey150: #BABABA;
$green50: #ECF4ED;
$green100: #b0d1b6;
$green200: #9FC8A3;
$green300: #3E9248;
$green400: #357C3D;
$red: #ED0B0B;
$red200: #BE0909;
$red300: #FCDADA;
$pink: #F68585;
$orange: #EFA12D;
$orange100: #F9E8C6;
$blue100: #C6E7F9;
$pink100: #F3D8DA;
$green150: #C8F9C6;
$brown: #564926;
$green600:#265636;
$red500:#831E19;
$blue500: #264256;
