.buttonText {
  &:global(.MuiButtonBase-root){
    min-width: auto;
    min-height: auto;
    width: auto;
    padding: 0;
    position: absolute;
    top: 25%;

    svg {
      font-size: 22px;
    }
  }
}