@import "src/shared/styles/common";

.wrapper{
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.spinnerWrap{
  display: flex;
  justify-content: center;
  align-items: center;
}
