@import "src/shared/styles/common";

%textTableHeaderDefault{
  @include fontFamily(Rubik);
  font-size: 14px;
  line-height: 22px;
  color: $black;
  font-style: normal;
}

.tableWrapper{
  margin-top: 8px;
}

.tableHeader{
  @extend %textTableHeaderDefault;
  padding: 0 8px 8px 0;
}

.textBold{
  font-weight: 500;
}

.bottomLine{
  padding-bottom: 8px;
  border-bottom: 1px solid $grey300;
  margin: 0 -24px;
}


